@media (max-width:$screen-xs-max){
	// Celphones - < 768

	.topo{
		.well,
		.logo{
			text-align:center;
		}


	}

	.header-menu{
		margin-left:-15px;
		margin-right:-15px;
	}

	#menu{
		margin-left:-15px;
		margin-right:-15px;
		border-top:1px solid darken(#D53535, 15%);
		max-height: calc(100vh - 60px);
		overflow:auto;

		a{
			padding:15px;
		}

		.active a,
		a:hover{
			background: darken(#D53535, 10%);
			color:#FFF;
		}
	}

	.gv8{
		margin-top:10px;
		margin-left:auto;
		margin-right:auto;
	}

	form{
		button.btn-enviar{
			width:100%;
		}
	}
}
