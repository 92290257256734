.descricao{
	font-size: 15px;
	line-height: 20px;
	color: #999999;
	font-family: 'Open Sans', sans-serif;
	font-weight: 500;
	text-align: justify;
	margin-bottom: 40px;
}

.box__servicos{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;

	@media(max-width: 1199px){
		grid-template-columns: repeat(2, 1fr);
	}

	@media(max-width: 991px){
		grid-template-columns: 1fr;
	}
}

.card__servico{
	width: 360px;
	height: 293px;
	background: #8d0404;
	position: relative;
	margin-left: auto;
	margin-right: auto;

	@media(max-width: 767px){
		width: 270px;
	}

	&:hover{
		.content__card{
			opacity: 1;
		}
	}

	.segura-card-servico{
		display: block;
		text-decoration: none;
		padding: 60px 20px 50px 20px;

		@media(max-width: 767px){
			padding: 15px;
		}
	}

	.icone{
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}

	.titulo__card{
		font-size: 20px;
		line-height: 25px;
		color: #fff;
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		text-align: center;
	}

	.content__card{
		position: absolute;
		width: 360px;
		height: 293px;
		padding: 40px 20px 30px 20px;
		left: 0;
		bottom: 0;
		background: #eeeeee;
		opacity: 0;
		transition: opacity .4s linear;

		@media(max-width: 767px){
			width: 270px;
			padding: 10px;
		}

		.titulo__card{
			color: #8d0404;
			margin-bottom: 10px;
		}

		.descricao__card{
			font-size: 15px;
			line-height: 20px;
			text-align: center;
			font-family: 'Open Sans', sans-serif;
			font-weight: 500;
			color: #999999;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 7;
			overflow: hidden;
			margin-bottom: 10px;

			P{
				margin-bottom: 0;
			}
		}
		.servico-btn{
			color: #fff;
			background-color: #8d0404;
			display: block;
			max-width: 150px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding: 5px 10px;
			border-radius: 10px;
			text-align: center;

			&:hover{
				text-decoration: none;
			}
		}
	}
}
