body{
	font-family: 'open_sansregular';
	line-height:1.5;
	font-size:15px;
}

a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.cmh-t{
	font-size:13px;
	color: #FFF;

	.title{
		font-family: 'open_sansbold';
		font-size:17px;
	}
}

.ops-bld{
	font-family: 'open_sansbold';
}

.text-danger{
	color: #D53535;
}

.mgr-5{
	margin-right:5px;
}

.bar-title{
	display: block;
	width:60px;
	height:5px;
	background: #9D9D9D;
}

.title-footer{
	font-family: 'open_sansbold';
	font-size:16px;
	color:#FFF;

}



.page-header{
	margin-top:0;
	border:none;
	margin-bottom:60px;

	h1{
		margin:0;
		display:flex;
		padding:10px;
		color: #FFF;
		background:#292a2e;
		position:relative;
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		align-items:center;
		font-size:  26px;

		& > *{
			flex-grow: 1;
		}

		span{
			padding-left:20px;
			border-left:3px solid #D53535;
			padding-right:20px;
		}

		.line{
			border:1px solid #FFF;
			display:block;
			width:100%;
			max-width: 290px;
		}
	}

	@media (max-width: $screen-xs-max){
		h1{
			margin-bottom:20px;

			&:after,
			&:before{
				content:'';
				display: block;
				height:100%;
				position:absolute;
				background: #292a2e;
				top:0;
				width: 100vw;
			}

			&:before{
				left:100%;
			}

			&:after{
				right:100%;
			}
		}
	}

	@media (min-width: $screen-sm-min){
		.row{
			display:flex;
			align-items:center;
		}

		.flx-right{
			text-align:right;
		}

		h1{
			&:after,
			&:before{
				content:'';
				display: block;
				height:100%;
				position:absolute;
				background: #292a2e;
				top:0;
			}

			&:before{
				width:200vw;
				right:100%;
			}

			&:after{
				width:30px;
				clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
				left:100%;
			}
		}
	}
}
