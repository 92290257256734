.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

lazyimage{
    display: inline-block;
}

.pdtb-30{
    padding:30px 0;
}

.pdtb-60{
    padding:60px 0;
}

.noticia-index{
    max-width:349px;
    margin-left:auto;
    margin-right:auto;
    background:#FFF;

    .foto{
        position:relative;
        padding-bottom: (245 / 349) * 100%;
        background:#eee;
    }

    lazyimage{
        position:absolute;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        overflow:hidden;
        background: url(#{$imgs}/loading.svg) center center no-repeat;

        &[loaded="true"]{
            background:none;
        }
    }

    figcaption{
        text-align:center;
        font-family: 'open_sansbold';
        padding:20px;
    }
}

.mglr-10{
    margin-left: 10px;
    margin-right: 10px;
}

#mapaContainer{
    position:relative;
    padding-bottom: 300px;
    margin-top:15px;
    background: #EEE url(#{$imgs}/loading.svg) center center no-repeat;

    iframe{
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
    }
}

lazyiframe{
    display: block;
}

.row.vitrine{
    display:flex;
    flex-wrap:wrap;
}

.paginacao > * + *{
    margin-left:-5px;
}

.btn-inverse{
    @include button-variant(#FFF,#111111,#111);
}