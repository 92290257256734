.banner__carousel{
	.carousel{
		position: relative;

		.carousel-control-prev{
			color: #fff;
			position: absolute;
			width: 30px;
			height: 30px;
			left: 10%;
			top: calc(50% - 15px);
			font-size: 30px;
		}

		.carousel-control-next{
			color: #fff;
			position: absolute;
			width: 30px;
			height: 30px;
			right: 10%;
			top: calc(50% - 15px);
			font-size: 30px;
		}
	}
}
.segura__banner__baixo{
	text-align: center;
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.secao__visita{
	background: #111111;
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;

	.titulo{
		font-size: 22px;
		line-height: 18px;
		font-weight: bold;
		font-family: 'Open Sans', sans-serif;
	}

	.descricao{
		font-weight: 500;
		font-family: 'Open Sans', sans-serif;
		margin-bottom: 0;
	}

	.btn__localizar{
		width: 190px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		background: #8d0404;
		font-size: 14px;
		font-weight: bold;
		font-family: 'Open Sans', sans-serif;
		transition:  background .4s linear;

		@media(max-width: 991px){
			margin-top: 20px;
		}

		&:hover{
			background: #700606;
		}
	}
}

.img-fluid{
	display: block;
	width: 100%;
	height: auto;
}

.box__video{
	position: relative;

	@media (min-width: 992px) {
		width: 600px;
		position: fixed;
		bottom: 5px;
		right: 0px;
		z-index: 999;
	}

	@media (max-width: 991px) {
		display: none;
	}

	#btnPlay{
		width: 50px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: 0;
		background: transparent;
		color: rgba(#fff, 0.7);
		font-size: 50px;
		position: absolute;
		z-index: 999;
		opacity: 0;
		left: calc(50% - 25px);
		bottom: 0;
		transition: opacity .4s linear;
	}

	.active{
		opacity: 1 !important;
	}

}

/******************************************
	BTN-WHATSAPP
*******************************************/
.btn__whatsapp{
	background: #01AB4C;
	position: fixed;
	z-index: 999;
	box-shadow: 0 0 8px rgba(0,0,0,.8);
	font-size: 40px;
	margin-left: 40px;
	margin-bottom: 55px;
	bottom: 0;
	border-radius: 50%;
}

.info{
	font-size: 13px;
	font-style: italic;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;

	i{
		margin-left: 5px;
		margin-right: 5px;
	}
}

.btn__whatsapp:hover{
	text-decoration: none;
	animation: rubberBand 1s linear;
}

.btn__whatsapp__wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	overflow: hidden;
}

svg{
	width: 1em;
	height: 1em;
	fill: #FFF;
}

.owl__fotos{
	position: relative;

	.owl-nav{

		.owl-prev{
			width: 40px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff !important;
			background: rgba(0, 0, 0, 0.542) !important;
			position: absolute;
			left: -10px;
			top: calc(50% - 30px);

		}

		.owl-next{
			width: 40px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff !important;
			background: rgba(0, 0, 0, 0.542) !important;
			position: absolute;
			right: -10px;
			top: calc(50% - 30px);
		}
	}
}

.btn-dark-wine{
	@include button-variant(#FFF,#551A1F,#551A1F);
}

.bg-grey{
	background: #D7D7D7;
}

.bg-brown{
	background: #7E6147;
	color:#FFF;
}

#parceiros{
	padding-left: 50px;
	padding-right: 50px;
	position:relative;

	.owl-nav{
		font-size: 30px;

		& > *{
			display: inline-block;
			position:absolute;
			top:50%;
			margin-top: -22.5px;
		}

		.owl-prev{
			left:0;
		}

		.owl-next{
			right:0;
		}
	}
}

.section-fale-conosco{
	background-size:cover;
	background-position:center top;
	background-repeat:no-repeat;
	color:#FFF;

	.form-control{
		background: none;
		border:2px solid #FFF;
		border-radius: 0;
		color:#FFF;

		@include placeholdIt(){
			color:#FFF;
		};
	}
}
