.carousel-area{
	padding-top:60px;
	padding-bottom:60px;
	overflow:hidden;
	background-size:cover;
	background-attachment:fixed;
	// min-height:100vh;
	// display:flex;
	// align-items:center;
}

#servicos{
	position:relative;
	width:100%;
	height:600px;
	max-height:600px;
	opacity: 0;

	&.loaded{
		opacity:1;
	}

	.cloud9-item{
		width:380px;
		max-width:50%;
	}
}

#buttons{
	.btn{
		margin: 0 10px;
		@include button-variant(#FFF, #87383E, rgba(#000,0.2));
		outline: none!important;
	}
}