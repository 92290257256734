.escritorio{
	border-bottom:5px solid #DFDFE0;
	margin-bottom:30px;

	lazyiframe{
		display: block;
		padding-bottom: (360 / 539) * 100%;
		position:relative;
		background:#EEE url(#{$imgs}/loading.svg) center center no-repeat;

		iframe{
			position:absolute;
			width:100%;
			height:100%;
			left:0;
			top:0;
		}
	}

	h2{
		@extend .ops-bld;
		color: #D53535;
		font-size:25px;
	}
}