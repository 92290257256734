@mixin fa-variant($color,$colorHover){
	color: $color;

	&:hover{
		color: $colorHover;
	}
}
.rodape{
	background: #111111;
	color:#999999;
	padding-top:30px;

	ul{
		list-style:none;
		padding-left:0;

		.active a,
		a:hover{
			color:#FFF;
			text-decoration:none;
		}
	}

	.redes-sociais{
		font-size:18px;
		margin-top:20px;
		margin-bottom:20px;
		font-size:30px;

		.fa{
			margin:0 15px;

			&:hover{
				text-decoration:none;
				animation: rubberBand 0.6s linear;
			}
		}

		.fa-facebook-official{
			@include fa-variant(#3B5998, lighten(#3B5998,10%))
		}

		.fa-twitter{
			@include fa-variant(#1DA1F2, lighten(#1DA1F2,10%))
		}

		.fa-google-plus{
			@include fa-variant(#DF483D, lighten(#DF483D,10%))
		}

		.fa-youtube{
			@include fa-variant(#DF2926, lighten(#DF2926,10%))
		}

		.fa-pinterest{
			@include fa-variant(#CB2027, lighten(#CB2027,10%))
		}
	}
}

.gv8{
	display: block;
	width:66px;
	height:21px;
	background: url(#{$imgs}/gv8.png);
}

.creditos{
	padding-top:20px;
	padding-bottom:20px;
	border-top:1px solid #999;

	@media (min-width: $screen-sm-min){
		.row,
		.col-sm-6:last-child{
			display:flex;
			align-items:center;
		}
		.col-sm-6:last-child{
			justify-content: flex-end;
		}

		.gv8{
			margin-left:15px;
		}
	}
}