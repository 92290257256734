.topo{
	background: #8d0404;


	.main__topo{
		background: #fff;
		padding-bottom: 16px;
		padding-top: 22px;
		border-bottom-left-radius: 170px;

		@media(max-width: 991px){
			border-bottom-left-radius: 0px;
		}
	}

	.logo{
		margin-right: 65px;

		@media(max-width: 991px){
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	.row{
		word-break:break-all;
	}

	.row__personalizado{
		display: flex;
		align-items: center;

		@media(max-width: 991px){
			flex-direction: column;
		}
	}

	.row__personalizado__2{
		display: flex;
		justify-content: space-between;

		@media(max-width: 991px){
			flex-direction: column;
			align-items: center;
		}
	}

	.box__endereco{
		width: 252px;
		margin-right: 35px;

		@media(max-width: 991px){
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	.box__contato{
		width: 252px;
		margin-right: 35px;
		@media(max-width: 991px){
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	.box__info{
		width: 200px;
	}

	.well{
		color: #fff;
		background: #8d0404;
		border: 0;
		margin-bottom: 0;

		.fa + .fa{
			margin-left:10px;
		}
	}

	.msg-boas-vindas{
		font-size: 13px;
		font-weight: 500;
		font-family: 'Open Sans', sans-serif;
		font-style: italic;

		@media(max-width: 767px){
			margin-bottom: 15px;
		}
	}

	.fa:hover{
		color: #C0332E;
	}

	.mb-15{
		margin-bottom: 10px;
	}

	.mb-50{
		margin-bottom: 50px;

		@media(max-width: 991px){
			margin-bottom: 20px;
		}
	}

	.cmh-t{
		position:relative;
		color: #000;
		display: flex;

		img{
			margin-right: 16px;
		}

		.icon{
			width:50px;
			float:left;
			margin-left:-50px;
		}
	}

	.content__numeracao{
		display: flex;
		align-items: center;
		font-size: 12px;
		margin-bottom: 15px;

		&:last-child{
			margin-bottom: 0;
		}
	}

	.box-numeracao{
		color: #000;
		font-size: 14px;
		font-weight: 700;
		font-family: 'Open Sans', sans-serif;

	}

	.redes-sociais{
		position: relative;
		padding-left: 20px;
		padding-right: 20px;

		&::after{
			content: '';
			width: 1px;
			height: 100%;
			background: #a86363;
			position: absolute;
			right: 0;
			bottom: 0;

			@media(max-width: 991px){
				display: none;
			}
		}

		a{
			margin-right: 20px;
			font-size: 20px;
			transition: color .4s linear;

			@media(max-width: 991px){
				margin-right: 10px;
			}

			&:hover{
				color: #000;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}
}

a.fa:hover{
	text-decoration:none;
}

.menu{
	background: #8d0404;
	font-size: 13px;
	height: 60px;
	padding-top: 5px;

	.header-menu{
		@extend .clearfix;
	}

	.content{
		max-width:1200px;
		padding:0 15px;
		margin:0 auto;
		@extend .clearfix;
	}

	&.affix{
		top:0;
		width:100%;
		z-index:1000;
		box-shadow:0 0 8px rgba(#000,0.8);
	}

	.btn:not(.btn-portal){
		border-radius:0;
		@include button-variant(#FFF, #8d0404,#8d0404);
		transition: background .4s linear;
		padding:15px 15px;
	}

	.btn-portal{
		text-align:left;

		&:hover,
		&:focus{
			color:#FFF !important;
		}

	}

}

.btn__externo{
	width: 200px;
	height: 50px !important;
	border-radius: 50px !important;
	display: flex;
	padding: 10px !important;
	justify-content: center;
	align-items: center;
	color: #fff;
	border: 5px solid #fff !important;
	background: #000 !important;
	transition: all .4s linear;
	background: rgb(26,97,3) !important;
	background: linear-gradient(90deg, rgba(26,97,3,1) 49%, rgba(172,244,134,1) 97%) !important;

	&:hover{
		color: #fff !important;
		background: rgba(172,244,134) !important;
		background: linear-gradient(90deg, rgba(172,244,134,1)  49%, rgba(26,97,3,1) 97%) !important;
	}
}

@media (min-width: $screen-sm-min){
	.header-menu{
		width:50px;
		float:right;
	}

	#menu{
		float:left;
		width: calc(100% - 60px);
	}
}

.toggle-menu{
	@extend .visible-xs;
}

#menu{
	list-style:none;
	padding:0;
	margin-bottom:0;
	color:#FFF;
	font-family: 'open_sansbold';

	a{
		display: block;

		&:hover{
			text-decoration:none;
		}
	}

	.dropdown{
		@include clearfix;
	}

	.open > a{
		color: #FFF;
	}

	.dropdown-menu{
		background: #fff;
		margin-top:0;
		border-radius:0;

		@media (max-width: $screen-xs-max){
			position:relative;
			display: block;
			width:100%;
			top:0;
		}

		&:before{
			content:'';
			display: block;
			border-left:10px solid transparent;
			border-right:10px solid transparent;
			border-bottom:10px solid #ffff;
			position:absolute;
			left:40px;
			bottom:100%;
		}

		a{
			color: #000;
		}

		.active a,
		a:hover,
		a:focus{
			background: #8d0404;
			color:#fff;
		}
	}
}

#buscas{
	.input-group{
		position:relative;
		background:#FFF;

		&,
		*{
			border-radius:0;
		}
	}

	.hover{
		position:absolute;
		width:0;
		height:3px;
		background: $brand-success;
		top:100%;
		transition:width 0.6s linear;
		left:0;
	}

	.form-control,
	.btn{
		border:none;
	}

	.btn{
		@include button-variant(#000,#FFF,#FFF);
	}

	.form-control:focus + .hover{
		width:100%;
	}
}
