@media (min-width: $screen-sm-min) and (max-width:$screen-sm-max){
	// Teblet -   > 768; < 992
	.msg-boas-vindas{
		float:left;
		max-width:48%;
		padding-top:10px;
		padding-bottom:10px;
	}

	.topo {
		.well.well-sm{
			padding:0;
		}

		.redes-sociais{
			float:right;
			border-left:1px solid #a86363;
			padding-top:10px;
			padding-bottom:10px;
			padding-left:10px;
		}
	}

	.logo{
		text-align:center;
	}

	#menu{
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;

		a{
			padding:15px 10px;
		}

		.active a, a:hover{
			color:#000;
		}
	}
}
