@media (min-width:$screen-md-min){
	// Desktop - >= 990px

	.msg-boas-vindas{
		float:left;
		max-width:48%;
		padding-top:10px;
		padding-bottom:10px;
	}

	.topo {
		.well.well-sm{
			padding:0;
		}

		.redes-sociais{
			float:right;
			border-left:1px solid #a86363;
			padding-top:10px;
			padding-bottom:10px;
			padding-left:10px;
		}
	}

	#menu{
		display:flex;

		& > li + li{
			margin-left:15px;
		}

		a{
			padding:15px;
		}

		.active a, a:hover{
			color:#000;
		}
	}

	.flx-row-md{
		display:flex;

		&.align-middle{
			align-items:center;
		}
	}
}
